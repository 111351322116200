<template>
  <m-widget-container
    ref="feedContainer"
    class="multi-row-swiper d-flex flex-column justify-start"
  >
    <v-card-title
      v-if="feedTitle"
      ref="titleContainer"
      class="justify-center white--text pa-0"
    >
      <v-icon
        :color="titleColor"
        left
        style="font-size: 2rem;"
      >
        mdi-instagram
      </v-icon>
      {{ feedTitle }}
    </v-card-title>
    <v-card-text class="align-center py-0 px-2">
      <template v-if="feed.length && heightCalculated">
        <swiper
          :options="swiperOptions"
          :style="`--active-bullet-color: ${titleColor};--swiper-pagination-padding: ${pagination ? '30px' : 'initial'};`"
        >
          <swiper-slide
            v-for="item in feed"
            :key="item.id"
          >
            <v-card
              flat
              class="ma-1"
            >
              <v-img
                eager
                aspect-ratio="1"
                :src="item.media_url"
              >
                <template #placeholder>
                  <div class="d-flex fill-height justify-center align-center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </div>
                </template>
                <marquee-text
                  v-if="marqueeCaptions"
                  class="instagram-marquee"
                  :duration="15 * (item.caption.length / 100)"
                >
                  {{ item.caption }}
                </marquee-text>
              </v-img>
            </v-card>
          </swiper-slide>
          <div
            v-if="pagination"
            slot="pagination"
            class="multi-row-swiper-pagination swiper-pagination"
          />
        </swiper>
      </template>
    </v-card-text>
  </m-widget-container>
</template>
<script>
import axios from 'axios'
import MarqueeText from 'vue-marquee-text-component'
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MInstagramFeed',
  components: {
    MarqueeText
  },
  mixins: [mWidgetMixin],
  props: {
    accessToken: {
      type: String,
      default: () => ''
    },
    feedTitle: {
      type: String,
      default: () => ''
    },
    titleColor: {
      type: String,
      default: () => 'info'
    },
    cols: {
      type: [String, Number],
      default: () => '3'
    },
    pagination: {
      type: Boolean,
      default: () => false
    },
    feedType: {
      type: String,
      default: () => 'hashtag'
    },
    hashtag: {
      type: Object,
      default: () => ({
        tag: 'schlossburg',
        type: 'top_media'
      })
    },
    businessPages: {
      type: Array,
      default: () => []
    },
    businessPageId: {
      type: String,
      default: () => ''
    },
    updateEveryMinutes: {
      type: Number,
      default: () => 15
    },
    marqueeCaptions: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: true,
      feed: [],
      updateInterval: null,
      heightCalculated: false,
      swiperOptions: {
        slidesPerView: this.cols + 0.2,
        slidesPerGroup: 1,
        spaceBetween: 5,
        touchRatio: 1,
        autoplay: {
          delay: 5000
        },
        preloadImages: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    anyBusinessPage() {
      return this.businessPages.find(x => !!x.instagramBusinessAccountId)
    }
  },
  watch: {
    anyBusinessPage: {
      handler(page) {
        if (!page) {
          this.updateInterval && clearInterval(this.updateInterval)
          return
        }
        if (this.feedType === 'hashtag' && this.hashtag.tag && this.hashtag.type) {
          this.loadHashtagMedia()
          this.updateInterval = setInterval(this.loadHashtagMedia, this.updateEveryMinutes * 60 * 1000)
        }
        if (this.feedType === 'business_page' && this.businessPageId) {
          this.loadBusinessPageMedia()
          this.updateInterval = setInterval(this.loadBusinessPageMedia, this.updateEveryMinutes * 60 * 1000)
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.updateInterval && clearInterval(this.updateInterval)
  },
  methods: {
    async loadHashtagMedia() {
      const { instagramBusinessAccountId, accessToken } = this.anyBusinessPage
      const params = {
        feedType: this.feedType,
        instagramBusinessAccountId,
        hashtagTag: this.hashtag.tag,
        hashtagType: this.hashtag.type,
        accessToken
      }
      const getInstagramFeed = this.$functions.httpsCallable('getInstagramFeed')
      const { data } = await getInstagramFeed(params)
      this.feed = data || []
      !this.heightCalculated && this.feed.length && this.calculateHeight()
    },
    async loadBusinessPageMedia() {
      const { instagramBusinessAccountId, accessToken } = this.businessPages.find(x => x.pageId === this.businessPageId)
      const { data } = await axios.get(`https://graph.facebook.com/v3.2/${instagramBusinessAccountId}/media?user_id=${instagramBusinessAccountId}&access_token=${accessToken}`)
      const fields = [
        'id',
        'caption',
        'media_type',
        'media_url',
        'like_count'
      ].join(',')
      this.feed = await Promise.all((data.data || []).map(async(item) => {
        const { data } = await axios.get(`https://graph.facebook.com/v3.2/${item.id}?fields=${fields}&user_id=${instagramBusinessAccountId}&access_token=${accessToken}`)
        return data
      }))
      !this.heightCalculated && this.feed.length && this.calculateHeight()
    },
    calculateHeight() {
      if (this.$refs.feedContainer) {
        let { offsetHeight } = this.$refs.feedContainer.$el
        const { offsetWidth } = this.$refs.feedContainer.$el
        if (this.$refs.titleContainer) {
          offsetHeight -= this.$refs.titleContainer.offsetHeight
        }
        const rows = offsetHeight / (offsetWidth / this.cols)
        this.swiperOptions.slidesPerColumn = Math.floor(rows)
        this.heightCalculated = true
      }
    }
  }
}
</script>

<style>
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  width: 100%;
}
.gallery-thumbs {
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
.multi-row-swiper .swiper-wrapper {
  flex-direction: row;
  padding-bottom: var(--swiper-pagination-padding);
}
.multi-row-swiper-pagination {
  bottom: var(--swiper-pagination-padding);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--active-bullet-color);
}
.instagram-marquee {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 0.5em 0;
  background: rgba(0, 0, 0, 0.7);
}
</style>
